import React from "react";
import { Seo } from "../../components/seo";
import SuccessBooking from "../../components/SuccessBooking/SuccessBooking";
import Layout from "../../hoc/Layout/Layout";

// markup
const IndexPage = ({ location }) => {
	return (
		<Layout >
			<Seo title="Tack för att du har bokat ett startmöte!" />
			<SuccessBooking location={location} />
		</Layout>
	);
};

export default IndexPage;
